import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const CorporateForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_D8iVRJF2k8k1FdgZBvemoH3h', null, captchaRef)}
      id="corporate-form"
    >
      <h3>Company Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="companyName"
        type="text"
        required
      />
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"
        type="text"
        required
      />
      <h3>Shipping Address *</h3>
      <input
        className="form-control"
        id="lastName"
        name="shippingAddress"
        type="text"
        required
      />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required>
        <option selected="selected">
          - select a state -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Zip / Postal Code *</h3>
      <input
        className="form-control"
        id="zipcode"
        name="zipCode"
        type="text"
        required
      />
      <h3>Phone *</h3>
      <input
        className="form-control"
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h3>Adult Tickets</h3>
      <input
        className="form-control"
        id="adultTickets"
        name="adultTickets"
        type="number"
      />
      <h3>Children Tickets</h3>
      <p>
        There is a minimum ticket order of 25 lift tickets. One complimentary
        ticket received for every 25 tickets purchased.
      </p>
      <input
        className="form-control"
        id="childrenTickets"
        name="childrenTickets"
        type="number"
      />
      <p>Tickets are non-refundable and illegal to resell.</p>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="creditCard"
            name="creditCard"
            type="checkbox"
            value="Credit Card"
          />
          <label htmlFor="creditCard">Credit Card</label>
        </li>
        <li className="l-item">
          <input
            className="checkbox"
            id="companyCheck"
            name="companyCheck"
            type="checkbox"
            value="Company Check"
          />
          <label htmlFor="companyCheck">Company Check</label>
        </li>
        <li className="l-item">
          <input
            className="checkbox"
            id="personalCheck"
            name="personalCheck"
            type="checkbox"
            value="Personal Check"
          />
          <label htmlFor="personalCheck">Personal Check</label>
        </li>
      </ul>
      <p>
        Orders will be processed at the time of receipt and fulfilled within
        7-10 business days
        <strong>while supplies last.</strong>
        upon arrival of delivery, tickets and cards must be signed for. Please
        supply a fedex deliverable address, no P.O. box addresses. please
        include drivers license & phone numbers on checks.
      </p>
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

CorporateForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

CorporateForm.defaultProps = {
  buttonText: null,
};
