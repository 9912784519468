import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const BulkTicketsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      id="bulk-tickets-form"
      onSubmit={(e) => processForm(e, 'buk_LC1ha8ZVSk8bUpFeHvlxdg8a', null, captchaRef)}
    >
      <h3>Group Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="groupName"
        type="text"
        required
      />
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"
        type="text"
        required
      />
      <h3>Shipping Address *</h3>
      <input
        className="form-control"
        id="lastName"
        name="shippingAddress"
        type="text"
        required
      />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required>
        <option selected="selected">
          - select a state -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Zip / Postal Code *</h3>
      <input
        className="form-control"
        id="zipcode"
        name="zipCode"
        type="text"
        required
      />
      <h3>Phone *</h3>
      <input
        className="form-control"
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h3>Number of People in Group</h3>
      <input
        className="form-control"
        id="peopleInGroup"
        name="peopleInGroup"
        type="number"
      />
      <p>
        A group must consist of 20 people in order to qualify for group rates.
      </p>
      <h3>Tentative Dates</h3>
      <p>Start Date</p>
      <input
        className="form-control"
        id="startDate"
        name="startDate"
        type="date"
      />
      <p>End Date</p>
      <input
        className="form-control"
        id="endDate"
        name="endDate"
        type="date"
      />
      <p>
        Once you qualify as a group, you will receive group rates on rentals and
        lessons as well. Please select your needs below.
      </p>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="rentals"
            name="rentals"
            type="checkbox"
            value="Rentals"
          />
          <label htmlFor="rentals">Rentals</label>
        </li>
        <li className="l-item">
          <input
            className="checkbox"
            id="lessons"
            name="lessons"
            type="checkbox"
            value="Lessons"
          />
          <label htmlFor="lessons">Lessons</label>
        </li>
        <li className="l-item">
          <input
            className="checkbox"
            id="lodging"
            name="lodging"
            type="checkbox"
            value="Lodging"
          />
          <label htmlFor="lodging">Lodging</label>
        </li>
      </ul>
      <p>
        Order requests must be submitted two weeks in advance from the group’s
        arrival date. Tickets are non-refundable and illegal to resell.
      </p>
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

BulkTicketsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

BulkTicketsForm.defaultProps = {
  buttonText: null,
};
