import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const AppFeedbackForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="app-feedback-form" onSubmit={(e) => processForm(e, 'buk_E7CRZrr8wq9O4UxZNaIRqYY9', null, captchaRef)}>
      <h3>First Name *</h3>
      <input
        name="firstName"
        id="firstName"
        className="form-control"
        type="text"
        required
      />

      <h3>Last Name *</h3>
      <input
        name="lastName"
        id="lastName"
        className="form-control"
        type="text"
        required
      />

      <h3>Email address *</h3>
      <input
        name="email"
        id="email"
        className="form-control"
        type="email"
        required
      />

      <h3>Comments *</h3>
      <textarea name="comments" id="comments" rows="9" cols="33" required />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

AppFeedbackForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

AppFeedbackForm.defaultProps = {
  buttonText: null,
};
