import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const WinterDonationsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_evxdK5disjhYW1GOvGjq5pyN', null, captchaRef)}
      id="winter-donations-form"
    >
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"
        type="text"
        required
      />
      <h3>Phone *</h3>
      <input
        className="form-control"
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h3>Organization's Name *</h3>
      <input
        className="form-control"
        id="organizationName"
        name="organizationName"
        type="text"
        required
      />
      <h3>What would you like us to donate? *</h3>
      <input
        className="form-control"
        id="yourDonation"
        name="yourDonation"
        type="text"
        required
      />
      <h3>What are your fundraising goals? *</h3>
      <textarea
        id="fundraisingGoals"
        rows="9"
        cols="33"
        name="fundraisingGoals"
        required
      />
      <h3>Event Type *</h3>
      <input
        className="form-control"
        id="eventType"
        name="eventType"
        type="text"
        required
      />
      <h3>Event Date *</h3>
      <input
        className="form-control"
        id="eventDate"
        name="eventDate"
        type="date"
        required
      />
      <h3>
        Is there anything else you'd like us to know about your organization? *
      </h3>
      <textarea
        id="knowAboutYourOrganization"
        rows="9"
        cols="33"
        name="knowAboutYourOrganization"
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

WinterDonationsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

WinterDonationsForm.defaultProps = {
  buttonText: null,
};
