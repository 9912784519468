import {
  ThemeProps as Prop,
  ColorProfiles,
} from '@powdr/constants';

import { defaultTheme } from './default';

export const altTheme = {
  ...defaultTheme,
};
