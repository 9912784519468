import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const MeetingsRFPForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_XERVEWhyFPxhF7W7HP0EdhvP', null, captchaRef)}
      id="meetings-rfp-form"
    >
      <h3>Organization *</h3>
      <input
        className="form-control"
        id="organization"
        name="organization"
        type="text"
        required
      />
      <h3>Key Contact *</h3>
      <input
        className="form-control"
        id="key-contact"
        name="keyContact"
        type="text"
        required
      />
      <h3>Mailing Address *</h3>
      <input
        className="form-control"
        id="mailingAddress"
        name="mailingAddress"
        type="text"
        required
      />
      <h3>City *</h3>
      <input
        className="form-control"
        id="city"
        name="city"
        type="text"
        required
      />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required>
        <option selected="selected">
          - select a state -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Phone Number w/ extension *</h3>
      <input
        className="form-control"
        id="phoneNumberWithExtension"
        name="phoneNumberWithExtension"
        type="tel"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h3>Arrival Date *</h3>
      <input
        className="form-control"
        id="arrivalDate"
        name="arrivalDate"
        type="date"
        required
      />
      <h3>Departure Date *</h3>
      <input
        className="form-control"
        id="departureDate"
        name="departureDate"
        type="date"
        required
      />
      <h3>Number of Attendees *</h3>
      <select
        className="form-control"
        id="numberAttendees"
        name="numberAttendees"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1-30">1-30</option>
        <option value="31-50">31-50</option>
        <option value="51-75">51-75</option>
        <option value="76-100">76-100</option>
        <option value="101+">101+</option>
      </select>
      <h3>Name of Event *</h3>
      <input
        className="form-control"
        id="nameOfEvent"
        name="nameOfEvent"
        type="text"
        required
      />
      <h3>Description of Event *</h3>
      <textarea
        id="descriptionOfEvent"
        rows="9"
        cols="33"
        name="descriptionOfEvent"
        required
      />
      <hr />
      <h2>Meeting Requirements:</h2>
      <h3>Number of Days *</h3>
      <select
        className="form-control"
        id="numberDays"
        name="numberDays"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1-5">1-5</option>
        <option value="6-10">6-10</option>
        <option value="11-20">11-20</option>
        <option value="21-40">21-40</option>
        <option value="41-60">41-60</option>
        <option value="61-80">61-80</option>
        <option value="81-100">81-100</option>
        <option value="100+">100+</option>
      </select>
      <h3>Hours *</h3>
      <input
        className="form-control"
        id="hours"
        name="hours"
        type="text"
        required
      />
      <h3>Meeting Name *</h3>
      <input
        className="form-control"
        id="meetingName"
        name="meetingName"
        type="text"
        required
      />
      <h3>Meeting Room Set *</h3>
      <input
        className="form-control"
        id="meetingRoomSet"
        name="meetingRoomSet"
        type="text"
        required
      />
      <h3>Number of Attendees *</h3>
      <select
        className="form-control"
        id="numberAttendees"
        name="numberAttendees"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1-5">1-5</option>
        <option value="6-10">6-10</option>
        <option value="11-20">11-20</option>
        <option value="21-40">21-40</option>
        <option value="41-60">41-60</option>
        <option value="61-80">61-80</option>
        <option value="81-100">81-100</option>
        <option value="100+">100+</option>
      </select>
      <h3>Additional Meeting Comments *</h3>
      <textarea
        id="additionalMeetingComments"
        rows="9"
        cols="33"
        name="additionalMeetingComments"
        required
      />
      <hr />
      <h2>Lodging Requirements:</h2>
      <h3>Number of nights *</h3>
      <select
        className="form-control"
        id="numberOfNights"
        name="numberOfNights"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="7+">7+</option>
      </select>
      <h3>Number of Rooms per Night *</h3>
      <select
        className="form-control"
        id="numberOfRoomsPerNight"
        name="numberOfRoomsPerNight"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1-5">1-5</option>
        <option value="6-10">6-10</option>
        <option value="11-20">11-20</option>
        <option value="21-40">21-40</option>
        <option value="41-60">41-60</option>
        <option value="61-80">61-80</option>
        <option value="81-100">81-100</option>
        <option value="100+">100+</option>
      </select>
      <h3>Occupancy in Rooms *</h3>
      <select
        className="form-control"
        id="occupancyInRooms"
        name="occupancyInRooms"
        required
      >
        <option selected="selected">
          - select -
        </option>
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
      <h3>Additional Lodging Comments *</h3>
      <textarea
        id="additionalLodgingComments"
        rows="9"
        cols="33"
        name="additionalLodgingComments"
        required
      />
      <hr />
      <h2>Recreation Requests:</h2>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="skiRide"
            name="skiRide"
            type="checkbox"
            value="Ski / Ride"
          />
          <label htmlFor="skiRide">Ski / Ride</label>
        </li>
      </ul>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="golf"
            name="golf"
            type="checkbox"
            value="golf"
          />
          <label htmlFor="golf">Golf</label>
        </li>
      </ul>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="teamBuilding"
            name="teamBuilding"
            type="checkbox"
            value="Team Building"
          />
          <label htmlFor="teamBuilding">Team Building</label>
        </li>
      </ul>
      <h3>Additional Recreation Comments *</h3>
      <textarea
        id="additionalRecreationComments"
        rows="9"
        cols="33"
        name="additionalRecreationComments"
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

MeetingsRFPForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

MeetingsRFPForm.defaultProps = {
  buttonText: null,
};
