import { ThemeProps as Prop } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#ffffff',
  },
  shades: {
    [Prop.LIGHTEST]: '#f0f0f0',
    [Prop.LIGHTER]: '#e0e0e0',
    [Prop.LIGHT]: '#cccccc',
    [Prop.GRAY]: '#8b8b8b',
    [Prop.GRAYER]: '#696969',
    [Prop.GRAYEST]: '#525257',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73c053',
    [Prop.INTERMEDIATE]: '#30a8e0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3b5998',
    [Prop.EVENTBRITE]: '#f6682f',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#FFB400',
    [Prop.DANGER]: '#FE4A49',
    [Prop.ALERT_WARNING_BG]: '#FFB400',
    [Prop.ALERT_WARNING_CONTENT]: '#000000',
    [Prop.ALERT_DANGER_BG]: '#FE4A49',
    [Prop.ALERT_DANGER_CONTENT]: '#FFFFFF',
  },
  tv: {
    [Prop.BACKGROUND]: '#18D5EE',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#DAFF01',
    [Prop.LINK]: '#241F19',
    [Prop.LINK_HOVER]: '#DAFF01',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
    [Prop.SIDE_NAV_ITEM_BG]: '#241F19',
    [Prop.SIDE_NAV_ITEM_TXT]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#241F19',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#241F19',
    [Prop.TOP_NAV_ITEM_TXT]: '#241F19',
    [Prop.ALERT_MARQUEE_BG]: '#DAFF01',
    [Prop.ALERT_MARQUEE_ALERT_TXT]: '#241F19',
    [Prop.ALERT_MARQUEE_TEMP_TIME_TXT]: '#241F19',
    [Prop.ALERT_OVERLAY_BG]: '#18D5EE',
    [Prop.ALERT_OVERLAY_TXT]: '#241F19',
    [Prop.ALERT_OVERLAY_TEMP_TIME_TXT]: '#241F19',
    [Prop.LIFTS_TXT]: '#241F19',
    [Prop.LIFTS_ICON]: '#DAFF01',
    [Prop.EVENT_1]: '#241F19',
    [Prop.EVENT_2]: '#241F19',
  },
};
