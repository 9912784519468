import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const WeddingsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_WQ2jQpkGFhd9cvnSMWv04CR6', null, captchaRef)}
      id="weddings-rfp-form"
    >
      <div className="form-group">
        <h3 className="u-inline">
          Where did you hear about Copper Mountain and our venues?
        </h3>
        <ul className="l-group">
          <li className="l-item">
            <input
              className="radio"
              id="research1"
              checked="checked"
              value="knot"
              type="radio"
              name="research"
            />
            <label htmlFor="research1">knot</label>
          </li>
          <li className="l-item">
            <input
              className="radio"
              id="research2"
              value="Wedding Sites + Services"
              type="radio"
              name="research"
            />
            <label htmlFor="research2">Wedding Sites + Services</label>
          </li>
          <li className="l-item">
            <input
              className="radio"
              id="research3"
              value="Other"
              type="radio"
              name="research"
            />
            <label htmlFor="research3">Other</label>
          </li>
        </ul>
        <h3>Other</h3>
        <input
          className="form-control"
          id="other"
          name="researchOther"
          type="text"
        />
        <h3>First Name *</h3>
        <input
          className="form-control"
          id="firstName"
          name="firstName"
          type="text"
          required
        />
        <h3>Last Name *</h3>
        <input
          className="form-control"
          id="lastName"
          name="lastName"
          type="text"
          required
        />
        <h3>Mailing Address *</h3>
        <input
          className="form-control"
          id="mailingAddress"
          name="mailingAddress"
          type="text"
          required
        />
        <h3>State *</h3>
        <select className="form-control" id="state" name="state" required>
          <option selected="selected">
            - select a state -
          </option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AB">Alberta</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="BC">British Columbia</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MB">Manitoba</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NB">New Brunswick</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="NT">Northwest Territories</option>
          <option value="NS">Nova Scotia</option>
          <option value="NU">Nunavut</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="ON">Ontario</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="PE">Prince Edward Island</option>
          <option value="QC">Quebec</option>
          <option value="RI">Rhode Island</option>
          <option value="SK">Saskatchewan</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
          <option value="YT">Yukon</option>
        </select>
        <h3>Zip / Postal Code *</h3>
        <input
          className="form-control"
          id="zipcode"
          name="zipCode"
          type="text"
          required
        />
        <h3>Phone *</h3>
        <input
          className="form-control"
          id="phoneNumber"
          name="phoneNumber"
          type="tel"
          required
        />
        <h3>Email address *</h3>
        <input
          className="form-control"
          id="email"
          name="email"
          type="email"
          required
        />
        <h3>Event Date/s *</h3>
        <input
          className="form-control"
          id="eventdate"
          name="eventdate"
          type="date"
          required
        />
        <h3>Are you flexible with your date?</h3>
        <input
          className="form-control"
          id="flexible"
          name="flexible"
          type="text"
        />
        <h3>Estimate Budget *</h3>
        <input
          className="form-control"
          id="budget"
          name="budget"
          type="text"
          required
        />
        <h3>Estimate number of invitation being sent? *</h3>
        <input
          className="form-control"
          id="invitations"
          name="invitations"
          type="text"
          required
        />
        <h3>Estimate Attendees? *</h3>
        <input
          className="form-control"
          id="attendees"
          name="attendees"
          type="text"
          required
        />
        <h3>Will there be dancing? *</h3>
        <input
          className="form-control"
          id="dancing"
          name="dancing"
          type="text"
        />
      </div>
      <hr />
      <h2>Lodging Requirements:</h2>
      <div className="form-group">
        <h3>Number of rooms needed</h3>
        <input className="form-control" id="rooms" name="rooms" type="text" />
      </div>
      <hr />
      <h2>Venue Needs:</h2>
      <div className="form-group">
        <h3>Do you need a ceremony site?</h3>
        <input
          className="form-control"
          id="ceremonySite"
          name="ceremonySite"
          type="text"
        />
        <h3>Preferred time of event?</h3>
        <input
          className="form-control"
          id="time"
          name="eventTime"
          type="text"
        />
        <h3>Is a rehearsal site needed?</h3>
        <input
          className="form-control"
          id="rehearsalSite"
          name="rehearsalSite"
          type="text"
        />
        <h3>Is a reception site needed?</h3>
        <input
          className="form-control"
          id="receptionSite"
          name="receptionSite"
          type="text"
        />
        <h3>Meeting space/alternative events?</h3>
        <input
          className="form-control"
          id="alternativeEvents"
          name="alternativeEvents"
          type="text"
        />
        <h3>Any special requests?</h3>
        <input
          className="form-control"
          id="specialRequests"
          name="specialRequests"
          type="text"
        />
        <h3>Are you available for a site visit?</h3>
        <input
          className="form-control"
          id="siteVisit"
          name="siteVisit"
          type="text"
        />
      </div>
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

WeddingsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

WeddingsForm.defaultProps = {
  buttonText: null,
};
