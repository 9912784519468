import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const DonationsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_f4Ep3Hi4mFY0Ym0LB4Pwsowu', null, captchaRef)}
      id="corporate-form"
    >
      <h3>Organization Name *</h3>
      <input
        className="form-control"
        id="organizationName"
        name="organizationName"
        type="text"
        required
      />
      <h3>Name *</h3>
      <input
        className="form-control"
        id="name"
        name="name"
        type="text"
        required
      />
      <h3>Email *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h3>Phone *</h3>
      <input
        className="form-control"
        id="phone"
        name="phone"
        type="tel"
        required
      />
      <h3>Address *</h3>
      <input
        className="form-control"
        id="address"
        name="address"
        type="text"
        required
      />
      <h3>City *</h3>
      <input
        className="form-control"
        id="city"
        name="city"
        type="text"
        required
      />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required>
        <option selected="selected">
          - select a state -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Zip *</h3>
      <input
        className="form-control"
        id="zip"
        name="zip"
        type="text"
        required
      />
      <h3>Website *</h3>
      <input
        className="form-control"
        id="website"
        name="website"
        type="text"
        required
      />
      <h3>Tax information (EIN) tax ID *</h3>
      <input
        className="form-control"
        id="taxid"
        name="taxid"
        type="text"
        required
      />
      <h3>Event date *</h3>
      <input
        className="form-control"
        id="eventDate"
        name="eventDate"
        type="tel"
        required
      />
      <h3>Tell us about your event *</h3>
      <textarea id="eventAbout" name="eventAbout" rows="9" required />
      <h3>What are you looking for as Donation *</h3>
      <textarea
        id="lookingForDonation"
        name="lookingForDonation"
        rows="9"
        required
      />
      <h3>Will Copper be recognized. How? *</h3>
      <textarea
        id="willCopperRecognizedHow"
        name="willCopperRecognizedHow"
        rows="9"
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

DonationsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

DonationsForm.defaultProps = {
  buttonText: null,
};
