import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const ContactUsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      id="contact-us-form"
      onSubmit={(e) => processForm(e, '', 'departmentToContact', captchaRef)}
    >
      <h3>First Name *</h3>
      <input
        name="firstName"
        id="firstName"
        className="form-control"
        type="text"
        required
      />

      <h3>Last Name *</h3>
      <input
        name="lastName"
        id="lastName"
        className="form-control"
        type="text"
        required
      />

      <h3>Phone *</h3>
      <input
        name="phoneNumber"
        id="phoneNumber"
        className="form-control"
        type="tel"
        required
      />

      <h3>Email address *</h3>
      <input
        name="email"
        id="email"
        className="form-control"
        type="email"
        required
      />

      <h3>Department to contact *</h3>
      <select
        name="departmentToContact"
        id="department"
        className="form-control"
        required
      >
        <option>--- Choose a Department ---</option>
        <option value="buk_t4NCP5dxVaXrSDqluk8axx64">Guest Feedback</option>
        <option value="buk_dELHJ8Aucdy7fEEC91HKCF1e">Lodging</option>
        <option value="buk_cot1wm3wgVuXOzn82Rd3Cw8G">Season Pass Office</option>
        <option value="buk_BWTUee0WyB3I48Mu6HCqjAIa">
          Groups and Conferences
        </option>
        <option value="buk_uCihqlGLQxX0Ja9H0UoZBE6Z">Employment</option>
        <option value="buk_IcC4UcyMRSXXb5aDX10Bq3F3">Lost and Found</option>
      </select>

      <h3>Message *</h3>
      <textarea name="message" id="message" rows="9" cols="33" required />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

ContactUsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

ContactUsForm.defaultProps = {
  buttonText: null,
};
