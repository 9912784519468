import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const HousingForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_3NgkEALM5p26JsKvdc7uA1Ux', null, captchaRef)}
      id="housing-form"
    >
      <h3>Are you a returning resident?</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="returningResident1"
            checked="checked"
            value="yes"
            type="radio"
            name="returningResident"
          />
          <label htmlFor="returningResident1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="returningResident2"
            value="no"
            type="radio"
            name="returningResident"
          />
          <label htmlFor="returningResident2">No</label>
        </li>
      </ul>
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"
        type="text"
        required
      />
      <h3>Nick Name *</h3>
      <input
        className="form-control"
        id="nickName"
        name="nickName"
        type="text"
        required
      />
      <h3>Mailing Address *</h3>
      <input
        className="form-control"
        id="mailingAddress"
        name="mailingAddress"
        type="text"
        required
      />
      <h3>City *</h3>
      <input
        className="form-control"
        id="city"
        name="city"
        type="text"
        required
      />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required>
        <option selected="selected">
          - select a state -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Zip / Postal Code *</h3>
      <input
        className="form-control"
        id="zipcode"
        name="zipCode"
        type="text"
        required
      />
      <h3>Country *</h3>
      <input
        className="form-control"
        id="country"
        name="country"
        type="text"
        required
      />
      <h3>Phone *</h3>
      <input
        className="form-control"
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        required
      />
      <h3>Email address *</h3>
      <input
        className="form-control"
        id="email"
        name="email"
        type="email"
        required
      />
      <h2>What is your hiring manager's info?</h2>
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="supervisorFirstName"
        name="supervisorFirstName"
        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="supervisorLastName"
        name="supervisorLastName"
        type="text"
        required
      />
      <h3>What is your department?</h3>
      <select className="form-control" id="department" name="department">
        <option selected="selected">
          - select -
        </option>
        <option value="Not a Copper employee">
          I am not a Copper Employee
        </option>
        <option value="Accounting">Accounting/Finance</option>
        <option value="Ambassadors">Ambassadors</option>
        <option value="Athletic Club/ Spa">Athletic Club/ Spa</option>
        <option value="Base operations">Base Operations</option>
        <option value="Call Center">Call Center</option>
        <option value="Childcare">Childcare</option>
        <option value="Conference Services">Conference/Event Services</option>
        <option value="Employee Experience">Employee Experience</option>
        <option value="Facilities Maintenance">Facilities Maintenance</option>
        <option value="Food and Beverage">Food and Beverage</option>
        <option value="Front Desk/Lodging">Front Desk/Lodging</option>
        <option value="Golf Course">Golf Course</option>
        <option value="Guest Services">Guest Services</option>
        <option value="Lift Operations">Lift Operations</option>
        <option value="Marketing">Marketing</option>
        <option value="Property Management">Property Management</option>
        <option value="Race Teams">Race Teams</option>
        <option value="Rental/Retail">Rental/Retail</option>
        <option value="Security">Security</option>
        <option value="Ski Patrol">Ski Patrol</option>
        <option value="Snowmaking">Snowmaking</option>
        <option value="Ski/Ride School">Ski/Ride School</option>
        <option value="Slope Maintenance">Slope Maintenance</option>
        <option value="Transportation">Transportation</option>
        <option value="Woodward">Woodward</option>
      </select>
      <h3>Employment start date *</h3>
      <input
        className="form-control"
        id="startDate"
        name="startDate"
        type="date"
        required
      />
      <h3>EMPLOYEE HOUSING MOVE IN DATE *</h3>
      <input
        className="form-control"
        id="moveinDate"
        name="moveinDate"
        type="date"
        required
      />
      <p>
        If this date changes, you must inform the Housing Office at
        <a
          href="mailto:employeehousing@coppercolorado.com"
          rel="noreferrer"
          target="_blank"
        >
          employeehousing@coppercolorado.com
        </a>
        or you will forfeit your place in housing. We require a minimum of 72
        hours from the time we receive your application for a move in.
      </p>
      <hr />
      <h2>Employee housing questionnaire:</h2>
      <h3>Gender</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="gender1"
            checked="checked"
            value="male"
            type="radio"
            name="gender"
          />
          <label htmlFor="gender1">Male</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="gender2"
            value="female"
            type="radio"
            name="gender"
          />
          <label htmlFor="gender2">Female</label>
        </li>
      </ul>
      <h3>Date of Birth *</h3>
      <input
        className="form-control"
        id="dateofbirth"
        name="dateofbirth"
        type="date"
        size="10"
        required
      />
      <p>Applicants must be 18 or over to be accepted for housing.</p>
      <h3>Do you smoke? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="smoking1"
            checked="checked"
            required
            value="yes"
            type="radio"
            name="smoking"
          />
          <label htmlFor="smoking1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="smoking2"
            required
            value="no"
            type="radio"
            name="smoking"
          />
          <label htmlFor="smoking2">No</label>
        </li>
      </ul>
      <h3>Do you drink alcoholic beverages? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="alcohol1"
            checked="checked"
            required
            value="yes"
            type="radio"
            name="alcohol"
          />
          <label htmlFor="alcohol1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="alcohol2"
            required
            value="no"
            type="radio"
            name="alcohol"
          />
          <label htmlFor="alcohol2">No</label>
        </li>
      </ul>
      <h3>Do you snore? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="snore1"
            checked="checked"
            required
            value="yes"
            type="radio"
            name="snore"
          />
          <label htmlFor="snore1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="snore2"
            required
            value="no"
            type="radio"
            name="snore"
          />
          <label htmlFor="snore2">No</label>
        </li>
      </ul>
      <h3>Do you consider yourself... *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="selfAssessment1"
            checked="checked"
            required
            value="A Neat Freak"
            type="radio"
            name="selfAssessment"
          />
          <label htmlFor="selfAssessment1">A Neat Freak</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="selfAssessment2"
            required
            value="Somewhat Messy"
            type="radio"
            name="selfAssessment"
          />
          <label htmlFor="selfAssessment2">Somewhat Messy</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="selfAssessment3"
            required
            value="Neither"
            type="radio"
            name="selfAssessment"
          />
          <label htmlFor="selfAssessment3">Neither</label>
        </li>
      </ul>
      <h3>What shift will you be working? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="shift1"
            checked="checked"
            required
            value="Day (before noon)"
            type="radio"
            name="shift"
          />
          <label htmlFor="shift1">Day (before noon)</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="shift2"
            required
            value="Night (after 3:00pm)"
            type="radio"
            name="shift"
          />
          <label htmlFor="shift2">Night (after 3:00pm)</label>
        </li>
      </ul>
      <h3>Which kind of room do you prefer? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="roomPreference1"
            required
            value="double"
            type="radio"
            name="roomPreference"
          />
          <label htmlFor="roomPreference1">Double (2 separate beds)</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="roomPreference2"
            required
            value="couple"
            type="radio"
            name="roomPreference"
          />
          <label htmlFor="roomPreference2">Couple (Share a bed)</label>
        </li>
      </ul>
      <h3>
        Do you have someone in particular that you want to request for a
        roommate? *
      </h3>
      <input
        className="form-control"
        id="roommateRequest"
        name="roommateRequest"
        type="text"
        required
      />
      <p>
        We do our best to use the information on your application to match you
        up with the best roommate possible. However, at high occupancy we are
        not always able to make a perfect match. We highly recommend that you
        find someone to live with you at the EDGE. If that is not an option for
        you, we appreciate your cooperation in living harmoniously with your
        assigned roommate.
      </p>
      <h2>Vehicle Owner Information</h2>
      <h3>
        Do you plan to have a car while employed by copper mountain resort?*
      </h3>
      <p>
        Overnight parking is only available in the North Alpine Lot during the
        winter season.Your vehicle must be moved twice/week for snow removal and
        lot maintenance. Failure to comply with our parking policy can result in
        fines, booting, and towing. We highly recommend that you do not bring a
        vehicle with you while you are living in the EDGE.
      </p>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="planToHaveCarWhileEmployed1"
            required
            value="yes"
            type="radio"
            name="planToHaveCarWhileEmployed"
          />
          <label htmlFor="planToHaveCarWhileEmployed1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="planToHaveCarWhileEmployed2"
            required
            value="no"
            type="radio"
            name="planToHaveCarWhileEmployed"
          />
          <label htmlFor="planToHaveCarWhileEmployed2">No</label>
        </li>
      </ul>
      <h3>Make</h3>
      <select className="form-control" id="vehicleMake" name="vehicleMake">
        <option selected="selected">
          - select -
        </option>
        <option value="Acura">Acura</option>
        <option value="Audi">Audi</option>
        <option value="BMW">BMW</option>
        <option value="Buick">Buick</option>
        <option value="Cadillac">Cadillac</option>
        <option value="Chevrolet">Chevrolet</option>
        <option value="Chrysler">Chrysler</option>
        <option value="Daewoo">Daewoo</option>
        <option value="Dodge">Dodge</option>
        <option value="Eagle">Eagle</option>
        <option value="Fiat">Fiat</option>
        <option value="Ford">Ford</option>
        <option value="GMC">GMC</option>
        <option value="Honda">Honda</option>
        <option value="Hummer">Hummer</option>
        <option value="Hyundai">Hyundai</option>
        <option value="Infinity">Infinity</option>
        <option value="Isuzu">Isuzu</option>
        <option value="Jaguar">Jaguar</option>
        <option value="Jeep">Jeep</option>
        <option value="Kia">Kia</option>
        <option value="Land Rover">Land Rover</option>
        <option value="Lexus">Lexus</option>
        <option value="Lincoln">Lincoln</option>
        <option value="Mazda">Mazda</option>
        <option value="Fiat">Mercedes-Benz</option>
        <option value="Mercury">Mercury</option>
        <option value="Mini">Mini</option>
        <option value="Mitsubishi">Mitsubishi</option>
        <option value="Nissan">Nissan</option>
        <option value="Oldsmobile">Oldsmobile</option>
        <option value="Pontiac">Pontiac</option>
        <option value="Porsche">Porsche</option>
        <option value="Saab">Saab</option>
        <option value="Saturn">Saturn</option>
        <option value="Smartcar">SmartCar</option>
        <option value="Subaru">Subaru</option>
        <option value="Suzuki">Suzuki</option>
        <option value="Tesla">Tesla</option>
        <option value="Toyota">Toyota</option>
        <option value="Volkswagen">Volkswagen</option>
        <option value="Volvo">Volvo</option>
      </select>
      <h3>Model</h3>
      <input
        className="form-control"
        id="vehicleModel"
        name="vehicleModel"
        type="text"
      />
      <h3>Color</h3>
      <input
        className="form-control"
        id="vehicleColor"
        name="vehicleColor"
        type="text"
      />
      <h3>Where is the car registered?</h3>
      <select
        className="form-control"
        id="vehicleRegistration"
        name="vehicleRegistration"
      >
        <option selected="selected">
          - select -
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>License Plate Number</h3>
      <input
        className="form-control"
        id="vehiclePlates"
        name="vehiclePlates"
        type="text"
      />
      <h2>Now it's your turn</h2>
      <h3>Do you have any additional comments?</h3>
      <textarea
        id="additionalComments"
        name="additionalComments"
        rows="9"
      />
      <h2>You agree to</h2>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="depositCheckbox"
            name="depositCheckbox"
            type="checkbox"
            value="Checked"
            required
          />
          <label htmlFor="depositCheckbox">
            Copper Employee Payment Policy *
          </label>
        </li>
      </ul>
      <p>
        Copper Employees -The $150 refundable security deposit, the $50.00
        non-refundable administration fee, and your first two weeks of rent are
        due at the time of move-in. The total minimumdue upon move-in (per
        person) for a double/couple room is $352.88.
      </p>
      <p>
        Priority for single rooms will be determined by Copper Mountain's
        Leadership Team.
      </p>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="checkbox"
            id="drugsCheckbox"
            name="drugsCheckbox"
            type="checkbox"
            value="Checked"
            required
          />
          <label htmlFor="drugsCheckbox">
            Copper Mountain is a Drug Free Resort *
          </label>
        </li>
      </ul>
      <p>
        Copper Mountain is a Drug Free Resort, and therefore The EDGE is a Drug
        Free building. There is a no tolerance policy for possessing illegal
        drugs and/or drug paraphernalia. Copper Mountain does not provide any
        exception to the Drug-Alcohol Policy for the use or possession of
        recreational or medical marijuana. By clicking this box you agree to
        abide by the Drug-Alcohol Policy of Copper Mountain as it pertains to
        Employment and Employee Housing. The full policy will be available upon
        completion of your employee paperwork.
      </p>
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

HousingForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

HousingForm.defaultProps = {
  buttonText: null,
};
